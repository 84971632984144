import React from "react"
import Cookies from "js-cookie"
import AppConfig from "../../config/app.config"
import Backdrop from "@material-ui/core/Backdrop";

export class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: 1,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.setState({ isLoading: 1 });
    if (Cookies.get("userToken") != null && Cookies.get("userToken") !== "") {
      if (Cookies.get("reqUrl") != null && Cookies.get("reqUrl") !== "") {
        const redirectUrl = window.atob(Cookies.get("reqUrl"))
        window.location.href = redirectUrl
      } else {
        window.location.href = AppConfig.API.REDIRECT_URL
      }
    } else {
      this.props.history.push("/sign-in")
    }
  }


  render() {
    return this.state.isLoading === 1 ? (
      <Backdrop style={{ zIndex: 100, color: "#003699" }} open>
        <img
          src={require("../../assets/images/Veuit-Spinner.gif")}
          style={{
            textAlign: "center",
            display: "block",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
          className="c-logo"
          alt="streamit"
        />
      </Backdrop>
    ) : (
      <>Home</>
    );
  }

}

export default Home
