import { all, call } from "typed-redux-saga/macro"
import { signUpSagas } from "./signUp/signUp.sagas"
import { signInSagas } from "./signIn/signIn.sagas"

export function* rootSaga() {
  yield* all([
    call(signUpSagas),
    call(signInSagas),
  ])
}
