import { takeLeading, put, all, call } from "redux-saga/effects";
import SignInActionTypes from "./signIn.types";
import { userLoginSuccess, userLoginFailure } from "./signIn.actions";
import AppConfig from "../../config/app.config";
import UserService from "../../services/user/user.service";
import { log } from "../../helpers/core.helper";
import Cookies from "js-cookie";

/** performs user login */
export function* loginUser({ payload }) {
  try {
    const signInData = {
      username: payload.username,
      password: payload.password,
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      sphereTypeUrl: AppConfig.API.SPHERE_TYPE_URL,
      deviceInfo: payload.deviceInfo,
    };
    const res = yield UserService.signIn(signInData);
    if (res.error != null) {
      yield put(
        userLoginFailure({
          loginSuccess: false,
          loading: false,
          err: res.error.response.data.message,
        })
      );
    } else {
      if (res["status"]) {
        var employeeData = null;

        window.sessionStorage.setItem("userToken", res.data.userToken);
        window.sessionStorage.setItem(
          "thingId",
          res.data?.thingId != null ? res.data?.thingId : -1
        );
        window.sessionStorage.setItem(
          "appSessionLogId",
          res.data?.appSessionLogId != null ? res.data?.appSessionLogId : -1
        );
        window.sessionStorage.setItem(
          "authorizedLoginId",
          res.data?.authorizedLoginId != null ? res.data?.authorizedLoginId : -1
        );
        window.sessionStorage.setItem("email", payload.username);

        window.localStorage.setItem("login", "1");

        window.sessionStorage.setItem(
          "authId",
          res.data.twoFactorAuthenticationLogId
        );

        yield put(
          userLoginSuccess({
            loginSuccess: true,
            loading: true,
            employeeData: employeeData,
          })
        );
      } else {
        yield put(
          userLoginFailure({
            loginSuccess: false,
            loading: false,
            err: "Invalid Credential.",
          })
        );
      }
    }
  } catch (err) {
    log("signIn.sagas", "loginUser", err);
    yield put(
      userLoginFailure({ loginSuccess: false, loading: false, err: err })
    );
  }
}

/** Saga for user login */
export function* userLogin() {
  yield takeLeading(SignInActionTypes.LOGIN_START, loginUser);
}

/**
 * Initialise all local sagas
 */
export function* signInSagas() {
  yield all([call(userLogin)]);
}
