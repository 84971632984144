import React from "react"
import MainRoute from "../router/main-route"
import Cookies from "js-cookie"
import AppConfig from "../config/app.config"

export class MainLayout extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0)
    if (
      Cookies.get("userToken") != null &&
      Cookies.get("userToken") !== "" &&
      window.sessionStorage.getItem("authId") == null &&
      window.sessionStorage.getItem("authId") === -1
    ) {
      if (Cookies.get("reqUrl") != null && Cookies.get("reqUrl") !== "") {
        const redirectUrl = window.atob(Cookies.get("reqUrl"))
        window.location.href = redirectUrl
      } else {
        window.location.href = AppConfig.API.REDIRECT_URL
      }
    } else {
      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.get("reqUrl") != null) {
        Cookies.set("reqUrl", queryParams.get("reqUrl"), {
          path: "/",
          secure: "true",
          sameSite: "None",
          domain: AppConfig.API.DOMAIN_URL,
        })
      }
      // this.props.history.push("/sign-in")
    }
  }
  render() {
    return (
      <>
        <div
          className="content-page"
          id="content-page"
        >
          <MainRoute />
        </div>
      </>
    )
  }
}

export default MainLayout
