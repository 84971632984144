import React from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { selectLoginStatus } from "../../store/signIn/signIn.selectors"
import { userLogin, userLoginFailure } from "../../store/signIn/signIn.actions"
import validator from "validator"
import { NotificationManager } from "react-notifications"
import "react-notifications/lib/notifications.css"
import Backdrop from "@material-ui/core/Backdrop"
import { ReactComponent as DOT } from "../../assets/images/DOT.svg"
import { ReactComponent as VeuitLogo } from "../../assets/images/veuit-logo.svg"
import { ReactComponent as BLueeye } from "../../assets/images/blue-eye.svg"
import { ReactComponent as Whiteeye } from "../../assets/images/white-eye.svg"
import Cookies from "js-cookie"
import AppConfig from "../../config/app.config"
import axios from "axios"
import UserService from "../../services/user/user.service"
import * as rdd from "react-device-detect"
import * as lodash from "lodash"

const mapStateToProps = (state) => {
  return {
    selectLoginStatus: selectLoginStatus(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      userLogin,
      userLoginFailure,
    },
    dispatch
  ),
})

var isNotifiaction = 0
const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

export class SignIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: 0,
      username: "",
      password: "",
      passwordType: "password",
      reqUrl: "",
      locationData: {},
    }
    this.handleUsernameChange = this.handleUsernameChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const { history, selectLoginStatus } = this.props
    if (window.sessionStorage.getItem("authId") != null) {
      history.push("/verification")
    } else {
      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.get("reqUrl") != null) {
        this.setState({
          username: "",
          password: "",
          reqUrl: queryParams.get("reqUrl"),
        })
        Cookies.set("reqUrl", queryParams.get("reqUrl"), {
          path: "/",
          secure: "true",
          sameSite: "None",
          domain: AppConfig.API.DOMAIN_URL,
        })
      } else {
        var encreptedUrl = window.btoa(AppConfig.API.REDIRECT_URL)
        this.setState({
          username: "",
          password: "",
          reqUrl: encreptedUrl,
        })
        Cookies.set("reqUrl", encreptedUrl, {
          path: "/",
          sameSite: "None",
          secure: "true",
          domain: AppConfig.API.DOMAIN_URL,
        })
      }
    }
    this.getlocationData()
  }

  async componentDidUpdate(prevProps, prevState) {
    const { selectLoginStatus, userLoginFailure } = this.props
    if (isNotifiaction === 1) {
      if (selectLoginStatus.success != null) {
        if (selectLoginStatus.success) {
          isNotifiaction = 0
          NotificationManager.success("Login Successfully.", "", 2000)

          let res = {}
          try {
            if (Object.keys(this.state.locationData).length === 0) {
              res = this.state.locationData
            } else {
              res = (await axios.get("https://ipapi.co/json")).data
            }
          } catch {}
          const userDeviceInfo = {
            userToken: window.sessionStorage.getItem("userToken"),
            browserName: rdd.browserName,
            browserVersion: rdd.browserVersion,
            osName: rdd.osName,
            osVersion: rdd.osVersion,
            deviceType: rdd.deviceType,
            isBrowser: rdd.isBrowser,
            isAndroid: rdd.isAndroid,
            isDesktop: rdd.isDesktop,
            isIOS: rdd.isIOS,
            isMobile: rdd.isMobile,
            isWindows: rdd.isWindows,
            isMacOs: rdd.isMacOs,
            country: res?.country_name ?? "",
            regionName: res?.region ?? "",
            city: res?.city ?? "",
          }
          isNotifiaction = 0
          NotificationManager.success("Login Successfully.", "", 2000)
          const list = JSON.parse(Cookies.get("device") ?? "[]")
          var isCookieDevice = false
          console.log(JSON.stringify([userDeviceInfo]))
          for (let i = 0; i < list.length; i++) {
            const row = list[i]
            if (lodash.isEqual(userDeviceInfo, row)) {
              console.log("yesss")
              isCookieDevice = true
            }
          }

          if (
            window.sessionStorage.getItem("authId") != null &&
            window.sessionStorage.getItem("authId") !== "-1" &&
            window.sessionStorage.getItem("authId") !== -1
          ) {
            if (
              window.sessionStorage.getItem("authorizedLoginId") != null &&
              window.sessionStorage.getItem("authorizedLoginId") !== "-1" &&
              window.sessionStorage.getItem("authorizedLoginId") !== -1 &&
              isCookieDevice
            ) {
              console.log(" login")
              this.setUserCookie()
            } else if (
              window.sessionStorage.getItem("authorizedLoginId") != null &&
              window.sessionStorage.getItem("authorizedLoginId") !== "-1" &&
              window.sessionStorage.getItem("authorizedLoginId") !== -1 &&
              !isCookieDevice
            ) {
              console.log(" login store device in cookies")
              this.setDeviceCookie(userDeviceInfo)
              this.setUserCookie()
            } else {
              console.log("otp")
              window.sessionStorage.setItem(
                "info",
                JSON.stringify(userDeviceInfo)
              )
              const { history } = this.props
              history.push("/verification")
            }
          } else {
            console.log(" login")
            this.setUserCookie()
          }

          // if (
          //   window.sessionStorage.getItem("authId") != null &&
          //   window.sessionStorage.getItem("authId") !== "-1" &&
          //   window.sessionStorage.getItem("authId") !== -1 &&
          //   !isCookieDevice &&
          //   !isRemember
          // ) {
          //   window.sessionStorage.setItem(
          //     "info",
          //     JSON.stringify(userDeviceInfo)
          //   );
          //   const { history } = this.props;
          //   history.push("/verification");
          // } else {
          //   const redirectUrl =
          //     Cookies.get("reqUrl") != null && Cookies.get("reqUrl") !== ""
          //       ? window.atob(Cookies.get("reqUrl"))
          //       : AppConfig.API.REDIRECT_URL;

          //   const cookiesPayload = {
          //     path: "/",
          //     domain: AppConfig.API.DOMAIN_URL,
          //     sameSite: "None",
          //     secure: "true",
          //     expires: 30,
          //   };

          //   Cookies.set(
          //     "userToken",
          //     window.sessionStorage.getItem("userToken"),
          //     cookiesPayload
          //   );
          //   Cookies.set(
          //     "thingId",
          //     window.sessionStorage.getItem("thingId"),
          //     cookiesPayload
          //   );
          //   Cookies.set(
          //     "appSessionLogId",
          //     window.sessionStorage.getItem("appSessionLogId"),
          //     cookiesPayload
          //   );
          //   Cookies.set(
          //     "authorizedLoginId",
          //     window.sessionStorage.getItem("authorizedLoginId"),
          //     cookiesPayload
          //   );

          //   this.timeoutHandle = setTimeout(() => {
          //     this.setState({ isLoading: 0 });
          //     window.sessionStorage.clear();
          //     window.location.href = redirectUrl;
          //   }, 3000);

          //   userLoginFailure({
          //     loginSuccess: false,
          //     loading: false,
          //     err: null,
          //   });
          // }
        } else {
          if (selectLoginStatus.error != null) {
            NotificationManager.error(selectLoginStatus.error, "", 2000)
            isNotifiaction = 0
            this.timeoutHandle = setTimeout(() => {
              this.setState({ isLoading: 0 })
            }, 3000)
            userLoginFailure({
              loginSuccess: false,
              loading: false,
              err: null,
            })
          }
        }
      }
    }

    if (document.cookie && document.referrer) {
      window.parent.postMessage(
        { type: "cookie", data: document.cookie },
        document.referrer
      )
      // console.log(window.parent);
    }
  }

  setUserCookie = async () => {
    const cookiesPayload = {
      path: "/",
      domain: AppConfig.API.DOMAIN_URL,
      sameSite: "None",
      secure: "true",
      expires: 30,
    }

    Cookies.set(
      "userToken",
      window.sessionStorage.getItem("userToken"),
      cookiesPayload
    )
    Cookies.set(
      "thingId",
      window.sessionStorage.getItem("thingId"),
      cookiesPayload
    )
    Cookies.set(
      "appSessionLogId",
      window.sessionStorage.getItem("appSessionLogId"),
      cookiesPayload
    )
    Cookies.set(
      "authorizedLoginId",
      window.sessionStorage.getItem("authorizedLoginId"),
      cookiesPayload
    )

    this.sendLoginNotification()

    const redirectUrl =
      Cookies.get("reqUrl") != null && Cookies.get("reqUrl") !== ""
        ? window.atob(Cookies.get("reqUrl"))
        : AppConfig.API.REDIRECT_URL

    this.timeoutHandle = setTimeout(() => {
      this.setState({ isLoading: 0 })
      window.sessionStorage.clear()
      window.location.href = redirectUrl
    }, 1000)

    userLoginFailure({
      loginSuccess: false,
      loading: false,
      err: null,
    })
  }

  sendLoginNotification = async () => {
    await UserService.sendLoginNotification({
      subscriptionId: AppConfig.API.SUBSCRIPTION_ID,
      verticalId: AppConfig.API.VERTICAL_ID,
      appId: AppConfig.API.APP_ID,
      email: window.sessionStorage.getItem("email"),
      timeZone: timeZone,
      appSessionLogId: parseInt(
        window.sessionStorage.getItem("appSessionLogId")
      ),
    })
  }

  setDeviceCookie = async (userDeviceInfo) => {
    const deviceArr = JSON.parse(Cookies.get("device") ?? "[]")
    const info = JSON.stringify(userDeviceInfo)
    if (deviceArr === "") {
      Cookies.set("device", JSON.stringify([JSON.parse(info.trim())]), {
        path: "/",
        domain: AppConfig.API.DOMAIN_URL,
        sameSite: "None",
        secure: "true",
        expires: 30,
      })
    } else {
      Cookies.set(
        "device",
        JSON.stringify([...deviceArr, JSON.parse(info.trim())]),
        {
          path: "/",
          domain: AppConfig.API.DOMAIN_URL,
          sameSite: "None",
          secure: "true",
          expires: 30,
        }
      )
    }
  }

  handleUsernameChange(event) {
    const removeExtraSpace = (s) => s.replace(/\s/g, "")
    this.setState({ username: removeExtraSpace(event.target.value) })
  }

  handlePasswordChange(event) {
    const removeExtraSpace = (s) => s.replace(/\s/g, "")
    this.setState({ password: removeExtraSpace(event.target.value) })
  }

  getlocationData = async () => {
    try {
      let res = (await axios.get("https://ipapi.co/json")).data
      this.setState({ locationData: res })
    } catch {}
  }

  signIn = async () => {
    const { userLogin } = this.props
    if (this.state.username === "") {
      NotificationManager.warning("Please Enter Email.", "", 2000)
    } else if (!validator.isEmail(this.state.username)) {
      NotificationManager.warning("Please Enter Valid Email.", "", 2000)
    } else if (this.state.password === "") {
      NotificationManager.warning("Please Enter Password.", "", 2000)
    } else if (this.state.password !== "" && this.state.username !== "") {
      const payload = {
        username: this.state.username,
        password: this.state.password,
        deviceInfo: {
          browserName: rdd.browserName,
          browserVersion: rdd.browserVersion,
          osName: rdd.osName,
          osVersion: rdd.osVersion,
          deviceType: rdd.deviceType,
          country: this.state.locationData?.country ?? "",
          regionName: this.state.locationData?.regionName ?? "",
          city: this.state.locationData?.city ?? "",
          postalCode: this.state.locationData?.country ?? "",
          latitude: this.state.locationData?.lat ?? "",
          longitude: this.state.locationData?.lon ?? "",
        },
      }
      isNotifiaction = 1
      this.setState({ isLoading: 1 })
      userLogin(payload)
    }
  }

  showPassword = () => {
    if (this.state.passwordType === "password") {
      this.setState({ passwordType: "text" })
    } else {
      this.setState({ passwordType: "password" })
    }
  }

  signInValidate = () => {
    if (!validator.isEmail(this.state.username) || this.state.password === "") {
      return false
    } else {
      return true
    }
  }

  render() {
    return (
      <section className="sign-in-page">
        {this.state.isLoading === 1 ? (
          <Backdrop
            style={{ zIndex: 100, color: "#003699" }}
            open
          >
            <img
              src={require("../../assets/images/Veuit-Spinner.gif")}
              style={{
                textAlign: "center",
                display: "block",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
              }}
              className="c-logo"
              alt="streamit"
            />
          </Backdrop>
        ) : (
          ""
        )}
        <Container id="container-signin">
          <Row className="justify-content-center align-items-center height-self-center m-0">
            <div id="set-sign-in1">
              <Col
                className="align-self-center set-sign-height"
                id="set-sign-in"
              >
                <div className="sign-user_card ">
                  <div className="sign-in-page-data">
                    <div className="sign-in-from w-100 m-auto">
                      {/* <h3 className="mb-3 text-center">Sign in</h3> */}
                      <div className="sign-in-logo">
                        <Link to="/">
                          <VeuitLogo id="info-logopages" />
                        </Link>
                      </div>
                      <div className="form-bg-mobile">
                        <div className="form-bg-mobile1">
                          <Form
                            id="set-input-width"
                            onSubmit={(e) => {
                              e.preventDefault()
                            }}
                          >
                            {/* <p className="login-acc">Log in to your account</p> */}
                            <Form.Group>
                              <Form.Control
                                type="email"
                                className="form-control mb-0 sign-in-input"
                                id="email"
                                placeholder="User Name"
                                value={this.state.username}
                                onChange={this.handleUsernameChange}
                                autoComplete="off"
                                required
                              />
                            </Form.Group>
                            <Form.Group>
                              <div className="input-group">
                                <Form.Control
                                  type={this.state.passwordType}
                                  className="form-control mb-0 sign-in-input-pwd"
                                  id="password"
                                  placeholder="Password"
                                  value={this.state.password}
                                  onChange={this.handlePasswordChange}
                                  required
                                />
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text sign-pwd-i-icon"
                                    id="inputGroupPrepend2"
                                    onClick={this.showPassword}
                                    style={{
                                      backgroundColor: "#141414",
                                      border: "1px solid #404043",
                                      color: "#fff",
                                    }}
                                  >
                                    {/* <i className={this.state.passwordType === 'password' ? 'd-eye-off' : 'd-eye-on'}></i> */}
                                    {this.state.passwordType === "password" ? (
                                      <BLueeye />
                                    ) : (
                                      <Whiteeye />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </Form.Group>
                            <div className="sign-in-forgot-pwd">
                              <div className="d-flex justify-content-center links">
                                <Link
                                  to="/forgot-password"
                                  className="f-link set-veuit-signin"
                                  id="set-veuit-signin"
                                >
                                  Forgot password?
                                </Link>
                              </div>
                              <div className="sign-vector">
                                <DOT className="sign-vector-img1" />
                              </div>
                              <div className="d-flex justify-content-center links">
                                <Link
                                  to="/sign-up"
                                  className="ml-2 set-veuit-signin"
                                >
                                  Sign up for VEUIT
                                </Link>
                              </div>
                            </div>
                            <div className="sign-info">
                              <Button
                                type="submit"
                                onClick={this.signIn}
                                className={`btn ${
                                  this.signInValidate() ? "" : "disabled"
                                }`}
                                id="sign-inbtn"
                              >
                                Login
                              </Button>
                              {/*<div className="custom-control custom-checkbox d-inline-block">
                                                    <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                    <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
                                                </div>*/}
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </section>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn))
