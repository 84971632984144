const URL = "http://192.168.1.206:8080/Ciright";

const AppConfig = {
  API: {
    ENDPOINT: URL + "/api",

    DOMAIN_URL: ".ciright.com",
    REDIRECT_URL: "https://veuit.ciright.com",
    SUBSCRIPTION_ID: 8263180,
    VERTICAL_ID: 2453,
    NETWORK_APP_ID: 2459,
    APP_ID: 2797,
    SPHERE_TYPE_URL: "veuit-login-main.htm",
    SPHERE_TYPE_ID: 2,
  },
};

export default AppConfig;

// const URL = "https://stage.myciright.com/Ciright";

// const AppConfig = {
//   API: {
//     ENDPOINT: URL + "/api",
//     DOMAIN_URL: ".ciright.com",
//     REDIRECT_URL: "https://veuit.ciright.com",
//     SUBSCRIPTION_ID: 8263180,
//     VERTICAL_ID: 2453,
//     NETWORK_APP_ID: 2459,
//     APP_ID: 2803,
//     SPHERE_TYPE_URL: "veuit-login-main.htm",
//     SPHERE_TYPE_ID: 2,
//   },
// };

// export default AppConfig;
