import { http } from "../../services/app/app.service";
import axios from "axios";
const UserService = {
  /**
    //  * User Sign in api call old api//m1972463
     * @param {object} signInData - the signin payload
     */
  signIn: (signInData: object) => {
    return http
      .post("/veuitNetwork/m2699245", signInData)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * User Sign Up api call
   * @param {object} signUpData - the signup payload
   */
  signUp: (signUpData: object) => {
    return http
      .post("/veuitNetwork/m1363584", signUpData)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * send reset password api call
   * @param {object} payload - the post data payload
   */
  sendResetPasswordLink: (payload: object) => {
    return http
      .post("/veuitNetwork/m1363730", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * validate reset key api call
   * @param {object} payload - the post data payload
   */
  validateResetKey: (payload: object) => {
    return http
      .post("/veuitNetwork/m1363731", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   * save reset password api call
   * @param {object} payload - the post data payload
   */
  saveResetPassword: (payload: object) => {
    return http
      .post("/veuitNetwork/m1363733", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   *send otp
   * @param {object} payload - the post data payload
   */
  sendOtp: (payload: object) => {
    return http
      .post("/commonadmin/m2732797", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  /**
   *veify otp
   * @param {object} payload - the post data payload
   */
  verifyOtp: (payload: object) => {
    return http
      .post("/commonadmin/m2702842", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
  getCookie: () => {
    return axios
      .get("/dashboard") // Run in development
      .then((res) => {
        const cookies = res.headers["set-cookie"];
        // console.log(res.request)
        // console.log(res.config.headers)
        // console.log(res.config.xsrfCookieName)
        // console.log(res)
        // if (cookies) {
        //   console.log('Cookies:', cookies);
        // } else {
        //   console.log('No cookies received');
        // }
        return res.headers;
      })
      .catch((error) => ({ error }));
  },
  /**
   *log out
   * @param {object} payload - the post data payload
   */
  logOut: (payload: object) => {
    return http
      .post("/commonadmin/m2724875", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   *Remember me
   * @param {object} payload - the post data payload
   */
  rememberMe: (payload: object) => {
    return http
      .post("/commonadmin/m2700047", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },

  /**
   *send Login Notification
   * @param {object} payload - the post data payload
   */
  sendLoginNotification: (payload: object) => {
    return http
      .post("/commonadmin/m2751765", payload)
      .then((res) => res.data)
      .catch((error) => ({ error }));
  },
};

export default UserService;
