import { Switch, Route } from "react-router-dom";
import MainLayout from "../layouts/main-layout";
import React from "react";
import { withRouter } from "react-router";
import AppConfig from "../config/app.config";
import Cookies from "js-cookie";

class LayoutsRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: 1,
      cookiesUserToken: "",
    };
    this.getInterval = null;
  }

  componentDidMount() {
    this.getInterval = setInterval(() => this.getSessionDetail(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.getInterval);
  }

  getSessionDetail = async () => {
    const cookiesUserToken = Cookies.get("userToken");
    if (
      cookiesUserToken != null &&
      cookiesUserToken !== undefined &&
      cookiesUserToken !== "" &&
      cookiesUserToken !== this.state.cookiesUserToken &&
      window.sessionStorage.getItem("authId") == null &&
      window.sessionStorage.getItem("authId") === -1
    ) {
      this.setState({ cookiesUserToken: cookiesUserToken });
      const redirectUrl =
        Cookies.get("reqUrl") != null && Cookies.get("reqUrl") !== ""
          ? window.atob(Cookies.get("reqUrl"))
          : AppConfig.API.REDIRECT_URL;
      window.location.href = redirectUrl;
    }
  };

  render() {
    return (
      <Switch location={this.props.location}>
        <Route path="/" component={MainLayout} />
      </Switch>
    );
  }
}

export default withRouter(LayoutsRoute);
